import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import stylesImages from "assets/jss/material-kit-react/views/componentsSections/exampleStyle.js";

import landing from "assets/img/landing.jpg";
import profile from "assets/img/profile.jpg";



const useStyles = makeStyles(styles);
const useStylesImages = makeStyles(stylesImages);

export default function ProductSection(props) {
  const classes = useStyles();
  const classesImages = useStylesImages();

  return (
    <div className={classes.section} style={{padding:0}}>
       <div className={classes.container}>

        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <h2>{props.doc && props.doc.titel1[0].text}</h2>
            {props.doc && props.doc.brodtext1[0].text}  
          </GridItem>
        </GridContainer>
      
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              className="makeStyles-title-282"
              title={props.doc && props.doc.titel2[0].text}
              description={props.doc && props.doc.brodtext2[0].text}
              icon={Chat}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
            className="makeStyles-title-282"
              title={props.doc && props.doc.titel3[0].text}
              description={props.doc && props.doc.brodtet[0].text}
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>        
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={4}>
            <img
              src={landing}
              alt="..."
              className={
                classesImages.imgRaised +
                " " +
                classesImages.imgRounded +
                " " +
                classesImages.imgFluid
              }
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <img
                src={profile}
                alt="..."
                className={
                  classesImages.imgRaised +
                  " " +
                  classesImages.imgRounded +
                  " " +
                  classesImages.imgFluid
                }
              />
          </GridItem>        
        </GridContainer>     </div>
  </div>
  );
}
