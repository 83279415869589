import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Favorite from "@material-ui/icons/Favorite";
import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import logo from "assets/img/EGNCTRL.png"
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>

      <div className={classes.container}>
       
          <GridContainer style={{marginTop:20}}>
            <GridItem xs={12} sm={12} md={3}>
              <img src={logo} width={200}></img>
              <br></br><br></br>
              <p>EGNCTRL är ett varumärke som tillhör App App App Sweden AB.</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <p style={{fontWeight: 900}}>Adress</p>
              <p>
              EGNCTRL<br></br>
              App App App Sweden AB<br></br>
              Ryttarvägen 23<br></br>
              132 45  Saltsjö-Boo</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <p style={{fontWeight: 900}}>Kontakt</p>
              <p>
              E-post info@egnctrl.se<br></br>
              Tel 08 - 663 60 00
              </p>
            
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <p style={{fontWeight: 900}}>Om EGNCTRL</p>
              <p>
              EGNCTRL är ett digitalt verktyg för egenkontroll. Våra kunder finns främst inom restaurang, catering och café. 
              </p>
            </GridItem>
          </GridContainer>

         
        </div>
        
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
