/*eslint-disable*/
import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(styles);

export default function HeaderLinks() {

  const classes = useStyles();

  const [open, setOpen] = React.useState(false)
  const [openContact, setOpenContact] = React.useState(false);
 
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenContact = () => {
    setOpenContact(true);
  };

  const handleCloseContact = () => {
    setOpenContact(false);
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [companyname, setCompanyName] = useState("");
  const [orgnumber, setOrgNumber] = useState("");

  const sendFeedback  = (templateId, variables) => { 
    window.emailjs.send(
      'gmail', templateId,
      variables
      ).then(res => {
        console.log('Email successfully sent!')
      })
      // Handle errors here however you like, or use a React error boundary
      .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
  }

  const handleSubmitContact = (evt) => {

    evt.preventDefault();
    alert(`Tack vi återkommer inom kort!`)
    const templateId = 'template_8AfcwXoM';
    sendFeedback(templateId, {phone: phone, message_html: message, from_name: name, reply_to: email})

    setName('')
    setEmail('')
    setPhone('')
    setMessage('')

    handleCloseContact()

  }


  const handleSubmitContract = (evt) => {

    evt.preventDefault();
    alert(`Tack vi återkommer inom kort!`)
    const templateId = 'template_8AfcwXoM';
    sendFeedback(templateId, {orgnumber: orgnumber, companyname: companyname, phone: phone, message_html: message, from_name: name, reply_to: email})

    setName('')
    setEmail('')
    setPhone('')
    setMessage('')
    setCompanyName('')
    setOrgNumber('')

    handleClose()

  }


  return (
    <List className={classes.list}>

      <ListItem className={classes.listItem}>
        <Button
          onClick={handleClickOpenContact}
          color="transparent"
          target="_blank"
          className={classes.navLink}
        > Kontakta oss
        </Button>
        
          <Dialog open={openContact} onClose={handleCloseContact} aria-labelledby="form-dialog-title">
            <form onSubmit={handleSubmitContact}>
            <DialogTitle id="form-dialog-title">Kontakta oss</DialogTitle>
            <DialogContent>
              <DialogContentText>
              Om du vill ha mer information och vill bli kontaktad. Fyll i ditt telefonnummer nedan så ringer vi upp dig, ange gärna i meddelandet vilka tider som passar bäst. Du kan även ange din mailadress så kontaktar vi dig via mail och bokar en tid.
              </DialogContentText>
             
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Fullständig namn"
                fullWidth
                onChange={e => setName(e.target.value)}
                value={name}
              />
              
              <TextField                
                margin="dense"
                id="email"
                label="Email"
                type="email"
                fullWidth
                onChange={e => setEmail(e.target.value)}
              />

              <TextField                
                margin="dense"
                id="phone"
                label="Telefonnummer"
                type=""
                fullWidth
                onChange={e => setPhone(e.target.value)}
              />

              <TextField                
                margin="dense"
                id="message"
                label="Meddelande"               
                fullWidth
                onChange={e => setMessage(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseContact} >
                Avbryt
              </Button>
              <Button type="submit" >
                Skicka
              </Button>
            </DialogActions> </form>
          </Dialog>
         
      </ListItem>
      <ListItem className={classes.listItem}>
       
          <Button
            onClick={handleClickOpen}
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >Kom igång
          </Button>

          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <form onSubmit={handleSubmitContract}>
            <DialogTitle id="form-dialog-title">Kom igång med EGNCTRL</DialogTitle>
            <DialogContent>
              <DialogContentText>
              Jag vill komma igång direkt. Kontakta mig för driftsättning.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Fullständig namn"                
                fullWidth
                onChange={e => setName(e.target.value)}
              />

              <TextField                
                margin="dense"
                id="companyName"
                label="Företagsnamn"                
                fullWidth
                onChange={e => setCompanyName(e.target.value)}
              />

              <TextField                
                margin="dense"
                id="orgnumber"
                label="Org. nummer"                
                fullWidth
                onChange={e => setOrgNumber(e.target.value)}
              />

              <TextField                
                margin="dense"
                id="phone"
                label="Telefonnummer"
            
                fullWidth
                onChange={e => setPhone(e.target.value)}
              />

              <TextField                
                margin="dense"
                id="email"
                label="Email"
                type="email"
                fullWidth
                onChange={e => setEmail(e.target.value)}
              />

            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} >
                Avbryt
              </Button>
              <Button type="submit" >
                Skicka
              </Button>
            </DialogActions>
            </form>
          </Dialog>
        
      </ListItem>
      <ListItem className={classes.listItem}>
        {/* <Tooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="#"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip> */}
      </ListItem>
      <ListItem className={classes.listItem}>
        {/* <Tooltip
          id="instagram-tooltip"
          title="Follow us on instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="#"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip> */}
      </ListItem>
    </List>
  );
}
