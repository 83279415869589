/*eslint-disable*/
import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// core components
import styles from "assets/jss/material-kit-react/views/componentsSections/downloadStyle.js";
import { RichText } from 'prismic-reactjs'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles(styles);

export default function SectionDownload(props) {
  
  const [openContact, setOpenContact] = React.useState(false)
  const [openDemo, setOpenDemo] = React.useState(false)

  const classes = useStyles();

  const handleOpenDemo = () => {
    setOpenDemo(true);
  };

  const handleCloseDemo = () => {
    setOpenDemo(false)
  };

  const handleClickOpenContact = () => {
    setOpenContact(true)
  };

  const handleCloseContact = () => {
    setOpenContact(false)
  };


  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const sendFeedback  = (templateId, variables) => { 
      window.emailjs.send(
        'gmail', templateId,
        variables
        ).then(res => {
          console.log('Email successfully sent!')
        })
        // Handle errors here however you like, or use a React error boundary
        .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
    }

  const handleSubmitContact = (evt) => {

    evt.preventDefault();
    alert(`Tack vi återkommer inom kort!`)
    const templateId = 'template_8AfcwXoM';
    sendFeedback(templateId, {phone: phone, message_html: message, from_name: name, reply_to: email})

    setName('')
    setEmail('')
    setPhone('')
    setMessage('')

    handleCloseContact()
    handleCloseDemo()
  }

  return (
    <div className={classes.section}>
      <div className={classes.container}>

        
        <GridContainer className={classes.textCenter} justify="center">
          <GridItem xs={12} sm={12} md={8}>
          <h2>{props.doc && props.doc.title_om[0].text}</h2>
           <RichText render={ props.doc && props.doc.brodtext_om} />
          </GridItem>
        </GridContainer>
        <br />
        <br />
        <GridContainer className={classes.textCenter} justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2>{props.doc && props.doc.titel_kostar[0].text}</h2>
            <RichText render={ props.doc && props.doc.brotext_kostar} />
          </GridItem>
        </GridContainer>
        <br></br>
        <GridContainer className={classes.textCenter} justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2>{props.doc && props.doc.titel4[0].text}</h2>
            <RichText render={ props.doc && props.doc.brodtext3} />
          </GridItem>
          <GridItem xs={12} sm={8} md={6}>
            <Button
              size="lg"
              onClick={handleClickOpenContact}
              target="_blank">
              Kontakta oss
            </Button>            
          </GridItem>
        </GridContainer>
        <br />
        <br />
        <GridContainer className={classes.textCenter} justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2>{props.doc && props.doc.titel5[0].text}</h2>
            <RichText render={ props.doc && props.doc.brodtext4} />
          </GridItem>
          <GridItem xs={12} sm={8} md={6}>
            <Button
              size="lg"
              onClick={handleOpenDemo}
              target="_blank">
              Boka demo
            </Button>
          </GridItem>
        </GridContainer>
       
        <Dialog open={openContact} onClose={handleCloseContact} aria-labelledby="form-dialog-title">
        <form onSubmit={handleSubmitContact}>
          <DialogTitle id="form-dialog-title">Kontakta oss</DialogTitle>
            <DialogContent>
              <DialogContentText>
              Om du vill ha mer information och vill bli kontaktad. Fyll i ditt telefonnummer nedan så ringer vi upp dig, ange gärna i meddelandet vilka tider som passar bäst. Du kan även ange din mailadress så kontaktar vi dig via mail och bokar en tid.

              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Fullständig namn"
                fullWidth
                onChange={e => setName(e.target.value)}
              />
              
              <TextField                
                margin="dense"
                id="name"
                label="Email"
                type="email"
                fullWidth
                onChange={e => setEmail(e.target.value)}
              />

              <TextField                
                margin="dense"
                id="name"
                label="Telefonnummer"
                type="phone"
                fullWidth
                onChange={e => setPhone(e.target.value)}
              />

              <TextField                
                margin="dense"
                id="message"
                label="Meddelande"               
                fullWidth
                onChange={e => setMessage(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseContact} >
                Avbryt
              </Button>
              <Button type="submit" >
                Skicka
              </Button>
            </DialogActions>
            </form>
        </Dialog>
        <Dialog open={openDemo} onClose={handleCloseDemo} aria-labelledby="form-dialog-title">
        <form onSubmit={handleSubmitContact}>
          <DialogTitle id="form-dialog-title">Boka demo</DialogTitle>
            <DialogContent>
              <DialogContentText>
              Jag vill ha en demo på hur jag kan underlätta mina kontroller. Fyll i ditt telefonnummer nedan så ringer vi upp dig, ange gärna i meddelandet vilka tider som passar bäst. Du kan även ange din mailadress så kontaktar vi dig via mail och bokar en tid. </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Fullständig namn"
                fullWidth
                onChange={e => setName(e.target.value)}
              />
              
              <TextField                
                margin="dense"
                id="email"
                label="Email"
                type="email"
                fullWidth
                onChange={e => setEmail(e.target.value)}
              />

              <TextField                
                margin="dense"
                id="name"
                label="Telefonnummer"

                fullWidth
                onChange={e => setPhone(e.target.value)}
              />

              <TextField                
                margin="dense"
                id="message"
                label="Meddelande"         
                onChange={e => setMessage(e.target.value)}      
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDemo} >
                Avbryt
              </Button>
              <Button type="submit" >
                Skicka
              </Button>
            </DialogActions></form>
        </Dialog>
        
      </div>
    </div>

    

  );
}
