import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionCompletedExamples from "./Sections/SectionCompletedExamples.js";
import SectionExamples from "./Sections/SectionExamples.js";
import SectionDownload from "./Sections/SectionDownload.js";
import ProductSection from "../LandingPage/Sections/ProductSection.js";
import styles from "assets/jss/material-kit-react/views/components.js";
import Prismic from 'prismic-javascript'
import { RichText } from 'prismic-reactjs'
import logo from "assets/img/EGNCTRL.png"

const apiEndpoint = 'https://egnctrl.cdn.prismic.io/api/v2'
const accessToken = '' // This is where you would add your access token for a Private repository
const Client = Prismic.client(apiEndpoint, { accessToken })

const useStyles = makeStyles(styles);
	
export default function Components(props) {

  const classes = useStyles();
  const { ...rest } = props;
  	
  const [doc, setDocData] = React.useState(null)

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await Client.query(
        Prismic.Predicates.at('document.type', 'homepage')
      )
      if (response) {
        console.log("response", response)
        setDocData(response.results[0])
      }
    }
    fetchData()
  }, [])

  return (
    <div>
      <Header
        brand="EGNCTRL"
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "white"
        }}
        {...rest}
      />
      <Parallax image={require("assets/img/bg4.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>           
              <img src={logo} width={300}></img> 
              {/* <span className="makeStyles-title-3 headerH1"><RichText render={ doc && doc.data.titel0} /></span> */}
                <span className="makeStyles-subtitle-4"><RichText render={ doc && doc.data.slogan} /></span>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>       
        <SectionCompletedExamples doc={doc && doc.data}/>
        <ProductSection doc={doc && doc.data}/>   
        <SectionDownload doc={doc && doc.data}/>
      </div>
      <Footer />
    </div>
  );
}
